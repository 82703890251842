import '../css/privacyterm.css'

function privacy() {
    return (
        <div className='body'>
            <h3>清波记App隐私政策</h3>
            <p>
                清波记由深圳市野离子网络科技有限公司开发的一款日记App，以下简称“本应用”。
                本应用非常重视隐私政策并严格遵守相关法律法规。请您仔细阅读《隐私政策》后再使用。
                如果您继续使用本应用，表示您已经充分阅读并且理解我们协议的全部内容。
            </p>
            <p>
                本app尊重并保护所有使用服务用户的个人隐私权。为了给您提供更好的服务，
                本应用会按照本隐私政策规定为您提供服务。除本隐私权政策另有规定外，
                在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
                您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
            </p>
            <h4>1. 适用范围</h4>
            <p>
                用户通过手机号码或第三方登录注册软件，在软件使用过程当中所制作填写的表格内容。
            </p>
            <table className="list" border={1}>
                <tr>
                    <th className="4">清波记App个人信息明示清单</th>
                </tr>
                <tr>
                    <td>业务场景</td>
                    <td>收集目的</td>
                    <td>收集方式</td>
                    <td>个人信息字段</td>
                </tr>
                <tr>
                    <td>注册/登录</td>
                    <td>用于创建账号，完成网络身份标识</td>
                    <td>用户输入</td>
                    <td>手机号</td>
                </tr>
                <tr>
                    <td>微信登录/QQ登录/微信支付</td>
                    <td>判断是否安装微信或QQ</td>
                    <td>App收集</td>
                    <td>软件安装列表</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>用于App崩溃分析</td>
                    <td>Buyly SDK获取</td>
                    <td>获取运行中进程信息</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>用于App崩溃分析</td>
                    <td>UMeng SDK获取</td>
                    <td>设备序列号/设备信息IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/ICCID/SN等</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>用于表格编辑复制粘贴</td>
                    <td>App收集</td>
                    <td>获取剪切板</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>表格填写</td>
                    <td>App收集</td>
                    <td>位置信息</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>表格填写</td>
                    <td>App收集</td>
                    <td>手机照片和媒体类型文件</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>表格交互</td>
                    <td>App收集</td>
                    <td>拨打和管理电话</td>
                </tr>
                <tr>
                    <td>APP前台运行时</td>
                    <td>统计分析</td>
                    <td>App收集</td>
                    <td>读取WIFI的BSSID</td>
                </tr>
                <tr>
                    <td>APP启动时</td>
                    <td>广点通SDK需要</td>
                    <td>广点通SDK收集</td>
                    <td>陀螺仪传感器,加速度传感器</td>
                </tr>
                <tr>
                    <td>登录注册</td>
                    <td>记录设备登录记录</td>
                    <td>App收集</td>
                    <td>Android ID</td>
                </tr>
            </table>
            <h4>2. 信息使用</h4>
            <p>
                (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易项目信息。
            </p>
            <p>
                (b)本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播相关项目信息。
                任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
            </p>
            <p>
                (c)本应用可以在“我的”页面，进入“我的”-&gt;"账号管理"页面，进行注销操作，注销之后立即生效，并会删除所有用户信息，不可再恢复，请谨慎操作。
            </p>
            <h4>3. 信息存储和交换</h4>
            <p>
                本应用收集的有关项目信息和资料将保存在建设单位的服务器上。软件使用需要获取手机【存储权限】，
                用来将表格分享到第三方社交平台以及存储表格图片等。
            </p>
            <h4>4. 本隐私政策的更改</h4>
            <p>
                (a) 如果决定更改隐私政策，我们会在本政策中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集项目信息。
            </p>
            <p>
                (b) 请您妥善保护自己的账号信息，如您发现自己的账号信息泄密，尤其是本应用用户名及密码发生泄露，
                请您立即联络本应用管理员，以便本应用采取相应措施。
            </p>
            <h4>5. 接入第三方SDK目录</h4>
            <table className="menu">
                <tr>
                    <th colSpan={5}>清波记App接入第三方SDK目录</th>
                </tr>
                <tr>
                    <td>第三方名称</td>
                    <td>使用的目的</td>
                    <td>功能类型</td>
                    <td>涉及个人信息</td>
                    <td>第三方官网链接</td>
                </tr>
                <tr>
                    <td>友盟SDK</td>
                    <td>辅助第三方登录和分享</td>
                    <td>统计分析和社会化分享</td>
                    <td className="left">设备信息IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/ICCID/SN/地理位置等</td>
                    <td className="left"><a href="https://developer.umeng.com/sdk">https://developer.umeng.com/sdk</a></td>
                </tr>
                <tr>
                    <td>微信SDK</td>
                    <td>使用微信登录清波记App</td>
                    <td>第三方登录</td>
                    <td>微信open id，微信头像以及昵称</td>
                    <td className="left"><a href="https://open.weixin.qq.com">https://open.weixin.qq.com</a></td>
                </tr>
                <tr>
                    <td>com.tencent.open(腾讯开放平台)</td>
                    <td>使用腾讯QQ登录清波记App</td>
                    <td>第三方登录</td>
                    <td>QQ open id，QQ头像以及昵称</td>
                    <td className="left"><a href="https://open.tencent.com">https://open.tencent.com</a></td>
                </tr>
                <tr>
                    <td>Bugly SDK</td>
                    <td>统计分析App崩溃和卡顿</td>
                    <td>统计分析</td>
                    <td>运行中进程信息、设备信息等</td>
                    <td className="left"><a href="https://bugly.qq.com/v2/">https://bugly.qq.com/v2/</a></td>
                </tr>
                <tr>
                    <td>com.qiniu(七牛云SDK)</td>
                    <td>保存用户插图</td>
                    <td>云存储</td>
                    <td>用户头像和表格插图</td>
                    <td className="left"><a href="https://www.qiniu.com">https://www.qiniu.com</a></td>
                </tr>
                <tr>
                    <td>优量汇(广点通)</td>
                    <td>广告投放</td>
                    <td>广告投放</td>
                    <td>读取WIFI的BSSID、设备信息、地理位置、软件安装列表等</td>
                    <td className="left"><a href="https://e.qq.com/dev/index.html">https://e.qq.com/dev/index.html</a></td>
                </tr>
                <tr>
                    <td>Mob SDK</td>
                    <td>验证码发送</td>
                    <td>手机号登录</td>
                    <td>手机号</td>
                    <td className="left"><a href="https://www.mob.com">https://www.mob.com</a></td>
                </tr>
            </table>
            <p>感谢您花时间了解我们的隐私政策！我们将尽全力保护您的个人信息和合法权益，再次感谢您的信任！</p>
            <p>联系方式：yangwei10086@foxmail.com</p>
            <p>更新日期：2025年5月28日<br />生效日期：2024年05月28日</p>
        </div>
    )
}

export default privacy;