import '../css/index.css'
import screenImg from './screen.jpg'
import appicon from './appicon.png'
import { Helmet } from 'react-helmet-async';

function index() {
    return (
        <div>
            <Helmet>
                <title>代购管家SunTable</title>
                {/* <link rel="icon" href="/favicon.ico" /> */}
            </Helmet>
            <div className='gradienty-one'>
                <img className='left-image' src={screenImg} />
                <div className='right-div'>
                    <img className='appicon' src={appicon} />
                    <p className='title'>代购管家SunTable</p>
                    <div className='detail'>
                        <p>提供全面稳定的代购客户、订单管理服务</p>
                        {/* <p>全平台，免费</p> */}
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div style={{ display: 'flex' }}>
                    <a href='./daigougj/privacyios'><p>《用户隐私协议》</p></a>
                    <a href='./daigougj/term'><p>《使用条款》</p></a>
                </div>

                <div style={{ display: 'flex', marginTop: 12 }}>
                    <a href='https://beian.miit.gov.cn/'><p style={{ marginRight: 20, fontSize: 14 }}>粤ICP备2021177159号-2</p></a>
                    <p style={{ fontSize: 14 }}>Copyright © 2024 野离子网络科技. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default index;