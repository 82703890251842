import '../css/index.css'

function term() {
    return (
        <div className='body'>
            <h3>代购管家SunTableApp用户使用条款</h3>
            <p>
                欢迎阅读《代购管家SunTableApp用户条款》(以下简称“本条款”)。本条款阐述之条款适用于您（以下简称“用户”）使用App的各种产品和服务。
            </p>
            <h4>1、服务内容</h4>
            <p>
                1.1 代购管家SunTableApp为用户提供日记写作功能。
                代购管家SunTableApp有权对软件提供的服务进行升级和调整，所有功能调整均以App内容更新的方式通知用户，不单独进行通知。
            </p>
            <p>
                1.2 代购管家SunTableApp不会对用户信息进行任何二次利用，进行销售等任何形式的获利。
            </p>
            <p>
                1.3 代购管家SunTableApp仅提供技术相关服务，除此之外的网络相关设备（如个人手机、平板等）及所需费用（如手机流量费用、WIFI上网费用等）
                均由用户自行承担。
            </p>
            <h4>2、服务条款的修改</h4>
            <p>
                本网站及APP有权在必要时修改条款，将会在页面公布。如果不接受所改动的内容，用户可以主动取消自己的会员资格。
                如果您不取消自己的会员资格，则视为接受服务条款的变动。
            </p>
            <h4>3、 用户的帐号、密码和安全性</h4>
            <p>
                一旦成功注册成为会员，您将有一个密码和用户名（第三方登录除外）用户将对用户名和密码的安全负全部责任。
                另外，每个用户都要对以其用户名进行的所有活动和事件负全责。您可以随时改变您的密码。
                用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告本公司。
            </p>
            <h4>4、拒绝提供担保</h4>
            <p>
                用户明确同意使用本公司服务，由用户个人承担风险。 本网站及APP不担保服务一定满足用户的要求，也不担保服务不会中断，
                对服务的及时性、安全性、出错发生都不作担保。 用户理解并接受：任何通过服务取得的信息资料的可靠性有用性取决于用户自己的判断，
                用户自己承担所有风险和责任。
            </p>
            <h4>5、有限责任</h4>
            <p>
                本网站及APP对任何由于使用服务引发的直接、间接、偶然及继起的损害不负责任。
                这些损害可能来自（包括但不限于）：不正当使用服务，或传送的信息不符合规定等。
            </p>
            <h4>6、对用户信息的存储和限制</h4>
            <p>
                本网站及APP不对用户发布信息的删除或储存失败负责，本公司有判定用户的行为是否符合服务条款的要求和精神的保留权利。
                如果用户违背了服务条款的规定，有中断对其提供服务的权利。
            </p>
            <h4>7、结束服务</h4>
            <p>
                本网站及APP可随时根据实际情况中断一项或多项服务，不需对任何个人或第三方负责或知会。 同时用户若反对任何服务条款的建议
                或对后来的条款修改有异议，或对服务不满，用户可以行使如下权利： **(1) **不再使用本公司的服务。
                **(2) **通知本公司停止对该用户的服务。
            </p>
            <h4>8、信息内容的所有权</h4>
            <p>
                本公司的信息内容包括：文字、软件、声音、相片、录象、图表；以及其它信息，所有这些内容受版权、商标、标签和其它财产所有权法律的保护。
                用户只能在授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
            </p>
            <h4>9、适用法律</h4>
            <p>
                上述条款将适用中华人民共和国的法律，所有的争端将诉诸于本网所在地的人民法院。如发生服务条款与中华人民共和国法律相抵触时，
                则这些条款将完全按法律规定重新解释，而其它条款则依旧保持约束力。
            </p>
            <h4>10、其他服务</h4>
            <p>
                软件在某些功能上进行VIP收费服务。
            </p>
            <p>
                目前有月、年、永久会员。收费为一次性收取，不会重复扣费。会员权益到期自动失效。具体收费金额以软件收费页面实时获取为准。
            </p>
            <p>联系方式：yangwei10086@foxmail.com</p>
            <p>更新日期：2024年05月28日<br />生效日期：2025年05月28日</p>
        </div>
    )
}

export default term;