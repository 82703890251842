import '../css/index.css'
import screenImg from './screen.png'

import { Helmet } from 'react-helmet-async';

function index() {
    return (
        <div>
            <Helmet>
                <title>Suntable</title>
            </Helmet>
            <div className='gradienty-one'>
                <img className='left-image' src={screenImg} />
                <div className='right-div'>
                    <p className='title'>Suntable</p>
                    <div className='detail'>
                        <p>简单、稳定、安全的手机表格制作软件</p>
                        <p>
                            - 小巧轻便，无门槛表格制作<br />
                            - 功能齐全，表格常用的操作都有<br />
                            - 数据云端存储，支持多设备同步<br />
                            - 表格模板多样，快速选择生成新表格<br />
                            - 稳定安全，数据加密上传，表格即改即存，随时恢复历史记录<br />
                            - 分享多样，支持图片分享和表格文件分享，兼容xlsx等excel格式<br />
                        </p>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div style={{ display: 'flex', marginTop: 12 }}>
                    <a href='https://beian.miit.gov.cn/'><p style={{ marginRight: 20, fontSize: 14 }}>粤ICP备2021177159号-2</p></a>
                    <p style={{ fontSize: 14 }}>Copyright © 2024 野离子网络科技. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default index;